export interface DatabaseSchema {
  /** values are indexes config, e.g. dpr_report: '+reportId, vesselId', where reportId is the primary key */
  [tableName: string]: string;
}

export enum ReportingTableNameEnum {
  DPR_VESSEL = 'dpr_vessel',
  DPR_REPORT = 'dpr_report',
  DPR_REF_DATA = 'dpr_ref_data',
  DPR_AUTOFILL_AIS_ACTIVITIES = 'dpr_autofill_ais_activities',
  SELECTED_VESSEL_CONFIG = 'selected_vessel_config',
}

/** This type allows to enforce typing on table names. Equivalent to { dpr_vessel: string, dpr_report: string, ... } */
export type ReportingDatabaseSchema = Record<ReportingTableNameEnum, string>;

export interface PersistentDbConfig<T> {
  /*
   * Note on Database versioning:
   *   Dexie does not support upgrades with changes in primary key. To circumvent this we simply create a new Dexie DB
   *   (change the name of the DB) when needed. Hence:
   *      - Incrementing databaseVersion.major will change the db name and create a whole new local db (when changing
   *        primary key e.g.)
   *      - Incrementing databaseVersion.minor will change the version number and perform all transactions specified in
   *        the upgrade function (when changing an index e.g.)
   */

  databaseVersion: {
    major: number;
    minor: number;
  };
  databaseName: string;
  databaseSchema: T;
}
